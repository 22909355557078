/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx } from 'theme-ui'
import Slider from 'react-slick'
import BackgroundImage from 'gatsby-background-image'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// .slick-slider {
//   height: 100%;
//   * {
//     height: 100%;
//   }
// }
const Carousel = ({ children, className, images, delay }) => {
  const [autoplay, setAutoplay] = useState(!delay)
  const [uniqueID, setID] = useState(delay ? 1 : 2)
  useEffect(() => {
    setTimeout(() => {
      setAutoplay(true)
      setID(2)
    }, 500)
  }, [])

  const defaultSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay,
    slidesToScroll: 1,
  }

  const Slide = (data, index) => {
    const imageData = data.childImageSharp.fluid
    return (
      <div
        sx={{
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        key={index}
      >
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        />
      </div>
    )
  }

  return (
    <Slider key={uniqueID} {...defaultSettings} className={className}>
      {images.map((img, index) => Slide(img, index))}
    </Slider>
  )
}

Carousel.defaultProps = {
  images: [],
  settings: {},
  slideIndex: 0,
}

export default Carousel
