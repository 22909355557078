/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link, graphql } from 'gatsby'
import MediaQuery from 'react-responsive'

import Layout from '@components/Layout'
import { Logo } from '@components/Logo'
import Carousel from '@components/Carousel'
import SEO from '@components/SEO'
const IndexPage = ({ data }) => {
  const sliderImages = Object.values(data)

  return (
    <Layout>
      <SEO title="Club" />
      <div>
        <div>
          <Logo stretch idle={false} color="var(--main-color)" />
          <MediaQuery maxWidth={569}>
            <Carousel className="home__slider" images={sliderImages} />
          </MediaQuery>
        </div>

        <div className="block-1">
          <div
            className="block-1-1"
            style={{ backgroundColor: 'var(--main-color)' }}
          >
            <h2 className="home__intro">
              Not a bar. Not a club. Radio Radio, Amsterdam, Westergas.
              <br />
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://pizzapizza.pizza"
              >
                Pizza Pizza
              </a>
              .
            </h2>
          </div>
          <div
            className="block-1-2"
            sx={{ backgroundColor: 'var(--main-color)' }}
          >
            <div className="block-1-2--img">
              <Carousel
                className="home__slider"
                images={sliderImages}
                delay={false}
              />
            </div>
            <div className="padding">
              <iframe
                title="soundcloud"
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1019792482&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=false&show_reposts=false&show_teaser=false&visual=true"
              ></iframe>
              <p>
                Full line-up on{' '}
                <a
                  href="https://www.facebook.com/radioradioradioradioradio/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </p>
              <MediaQuery maxWidth={999}>
                <p>
                  Subscribe to our{' '}
                  <a
                    href="http://news.radioradio.radio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    newsletter
                  </a>
                </p>
              </MediaQuery>
            </div>
            <MediaQuery minWidth={1000}>
              <div className="padding newsletter">
                <p>
                  Subscribe to our{' '}
                  <a
                    href="http://news.radioradio.radio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    newsletter
                  </a>
                  {/* <MediaQuery minWidth={1000}> */}
                  <br /> &nbsp; <br /> &nbsp;
                  {/* </MediaQuery> */}
                </p>
              </div>
            </MediaQuery>
          </div>
        </div>

        {/* Block 2 */}
        <div className="block-2">
          <div className="block-2--img">
            <Carousel
              className="home__slider"
              images={sliderImages}
              delay={true}
            />
          </div>
          {/* Right column */}
          <MediaQuery minWidth={1000}>
            <div
              className="block-2-1 block__address"
              style={{ backgroundColor: 'var(--main-color)' }}
            >
              <span>
                <Link to="/contact">Pazzanistraat 3, Amsterdam</Link>
              </span>
              <p>
                Thursday 18.00 - 1.00
                <br />
                Friday/Saturday 18.00 &mdash; 3.00
              </p>
            </div>
          </MediaQuery>
        </div>
        <MediaQuery maxWidth={999}>
          <div className="block-2-1 block__address">
            <span>
              <Link to="/contact">Pazzanistraat 3, Amsterdam</Link>
            </span>
            <p>
              Open every Thursday 18.00 - 1.00
              <br />
              Friday/Saturday 18.00 &mdash; 3.00
            </p>
          </div>
        </MediaQuery>
      </div>
    </Layout>
  )
}

export default IndexPage

export const sliderImg = graphql`
  fragment sliderImg on File {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "homeslider/bw_1.jpeg" }) {
      ...sliderImg
    }
    image2: file(relativePath: { eq: "homeslider/bw_2.jpeg" }) {
      ...sliderImg
    }
    image3: file(relativePath: { eq: "homeslider/bw_4.jpeg" }) {
      ...sliderImg
    }
    image4: file(relativePath: { eq: "homeslider/bw_5.jpeg" }) {
      ...sliderImg
    }
    image5: file(relativePath: { eq: "homeslider/col_1.jpeg" }) {
      ...sliderImg
    }
    image6: file(relativePath: { eq: "homeslider/col_2.jpeg" }) {
      ...sliderImg
    }
    image7: file(relativePath: { eq: "homeslider/bw_dj-v.jpeg" }) {
      ...sliderImg
    }
    image8: file(relativePath: { eq: "homeslider/bw_dancing-queen.jpeg" }) {
      ...sliderImg
    }
  }
`
